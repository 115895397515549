/*
  @file Controller component to check user confirmed state, and redirect to Verify route if not
  - this caches confirmed state permanently, meaning if an account ever goes confirmed -> uncofirmed, this controller will not work
  - this is a workaround until a cleaner solution can be implemented in the backend
  - note: while the endpoint is called "email status" it works for social auth as well
  */
import { useEffect } from 'react'
import { useGetEmailStatusAction } from '@commonstock/common/src/api/auth'
import { Routes } from '../nav/constants'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { AccountConfirmedKey, InitSessionTokenKey } from './constants'
import { useRouter } from 'next/router'

// We use an enum here because we cannot persist booleans
enum ConfirmedState {
  Yes = 'yes',
  No = 'no'
}
function VerifyController() {
  let [initSessionToken] = usePersistedState<string | null>(InitSessionTokenKey, null)
  let getEmailStatus = useGetEmailStatusAction()
  let [confirmed, setConfirmed, pending] = usePersistedState<ConfirmedState>(AccountConfirmedKey, ConfirmedState.No)
  const router = useRouter()

  useEffect(() => {
    if (initSessionToken && !pending && confirmed === ConfirmedState.No) {
      getEmailStatus({ headers: { Authorization: `Bearer ${initSessionToken}` } })
        .then(data => {
          setConfirmed(data.success?.payload.confirmed ? ConfirmedState.Yes : ConfirmedState.No)
          if (!data.success?.payload.confirmed) {
            router.replace(Routes.Verify)
          }
        })
        .catch(err => {
          console.error('## getEmailStatus err:', err)
        })
    }
  }, [pending, confirmed, setConfirmed, getEmailStatus, router, initSessionToken])

  return null
}

export default VerifyController
